// Loader
import React from "react";
import styles from "./Loader.module.sass";
import cn from "classnames";

export interface LoaderProps {
  size?: 24 | 32 | 40 | 48 | 56 | 64;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ size = 56, className }) => {
  return (
    <div className={cn(styles[`loader${size}`], className)}>
      <span />
    </div>
  );
};

export default Loader;
