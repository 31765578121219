const COLOR_NAMES = [
  "primaryYellow",
  "primaryGreen",
  "primaryRed",
  "primaryPurple",
  "completedBlue",
  "orange",
  "secondaryRed",
  "secondaryPurple",
  "secondaryBlue",
  "secondaryGreen",
  "secondaryYellow",
  "neutral00",
  "neutral01",
  "neutral02",
  "neutral03",
  "neutral04",
  "neutral05",
  "neutral06",
  "neutral07",
  "neutral08",
] as const;

export type ColorName = typeof COLOR_NAMES[number];

export const isThemeColor = (name: string): name is ColorName => {
  return COLOR_NAMES.includes(name as any);
};
