// Icon
import React from 'react';
import { ColorName, isThemeColor } from 'types/theme';
import { IconName, ICONS } from './Icons';
import styles from 'styles/_variables.module.sass';
interface IconProps {
  name: IconName;
  width?: number | 'auto';
  height?: number | 'auto';
  size?: number | 'auto';
  className?: string;
  onClick?: () => void;
  color?: string | ColorName;
  showNotification?: boolean;
}

const Icon: React.FC<IconProps> = ({
  name,
  width,
  height,
  size = 24,
  className,
  onClick,
  color,
}) => {
  let fillColor = color || '';
  width = width || size;
  height = height || size;

  if (isThemeColor(fillColor)) {
    fillColor = styles[fillColor];
  }

  if (name === 'rectangle') {
    if (typeof width === 'string' || typeof height === 'string') {
      throw new Error('`rectangle` Icon can not use `auto` for sizes');
    }
    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox={getViewBox(name, { height, width })}
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        fill="none"
      >
        <rect
          x="1"
          y="1"
          width={width - 2}
          height={height - 2}
          fill="none"
          stroke={fillColor}
          strokeWidth="2"
        />
      </svg>
    );
  }
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={getViewBox(name, { height, width })}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      fill={fillColor}
    >
      {ICONS[name]}
    </svg>
  );
};

export default Icon;

type Dimensions = Pick<IconProps, 'height' | 'width'>;

const getViewBox = (name: IconName, { width, height }: Dimensions) => {
  switch (name) {
    case 'rectangle':
    case 'paper-plane':
      // case 'verified-shield':
      return `0 0 ${width} ${height}`;
    case 'verified-shield':
      return `0 0 32 32`;
    case 'alert-circled':
      return '0 0 15 15';
    case 'arrow-counter-clockwise':
      return '-2 -1 18 18';
    case 'photos':
      return '0 0 22 22';
    case 'video-film':
      return '0 0 22 20';
    case 'story-book':
      return '0 0 24 18';
    case 'play-video':
      return `0 0 17 21`;
    default:
      return '0 0 24 24';
  }
};
